<template>
    <div class="title-wrapper">
        <h1>IRON MIKE</h1>
        <h2>PRÓXIMAMENTE</h2>
    </div>
    <Renderer ref="renderer" antialias orbit-ctrl resize="window">
        <Camera :position="{ z: 15 }"></Camera>
        <Scene background="#111111">
            <AmbientLight color="#808080"></AmbientLight>
            <PointLight color="#ffffff" :position="{ y: 50, z: 0 }"></PointLight>
            <PointLight color="#ffffff" :position="{ y: -50, z: 0 }"></PointLight>
            <PointLight color="#ffffff" :position="{ y: 0, z: 0 }"></PointLight>
            <Torus
                v-for="i in n" :key="i" :ref="el => { mesh[i] = el}"
                :radius="i * 0.2"
                :tube="0.1"
                :radial-segments="8"
                :tubular-segments="(i+2) * 4"
            >
                <ToonMaterial :color="color(i)"></ToonMaterial>
            </Torus>
        </Scene>
    </Renderer>
</template>

<script setup>
import {onBeforeUpdate, onMounted, ref} from "vue";
import {Renderer, Scene, ToonMaterial, Torus, PointLight, AmbientLight, Camera} from "troisjs";
import chroma from "chroma-js";

const n = ref(30);
const colorScale = ref(chroma.scale(['#dd3e1b', '#0b509c']));
const renderer = ref(null)
const mesh = ref([])

onMounted(() => {
    renderer.value.onBeforeRender(() => {
        const t = Date.now();
        let meshRef, ti;
        for (let i = 1; i <= n.value; i++) {
            meshRef = mesh.value[i].mesh;
            ti = (t - i * 500);
            meshRef.rotation.x = ti * 0.00015;
            meshRef.rotation.y = ti * 0.00020;
            meshRef.rotation.z = ti * 0.00017;
        }
    });
})

onBeforeUpdate(() => {
    mesh.value = [];
})

function color(i) {
    return colorScale.value(i / n.value).css();
}
</script>

<style lang="scss">
body {
    margin: 0;
    overflow: hidden;
}

canvas {
    display: block;
}

.title-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 8rem;
        font-family: 'Staatliches', cursive;
        color: white;
        margin: 0;

        @media screen and (max-width: 420px) {
            font-size: 5rem;
        }
    }

    h2 {
        font-size: 40px;
        font-family: 'Staatliches', cursive;
        color: rgba(255, 255, 255, 0.7);
        letter-spacing: 20px;
        margin: -2rem 0 0 19px;

        @media screen and (max-width: 420px) {
            font-size: 17px;
            letter-spacing: 16px;
            margin: -1rem 0 0 17px;
        }
    }
}
</style>
